import { AUTHORITY_CODE, ENDPOINT } from '@/lib/utils';

export async function getLocalesFx() {
  try {
    const response = await fetch(`${ENDPOINT}/main-page/get-langs`, {
      headers: { code: AUTHORITY_CODE },
      next: {
        revalidate: 60,
      },
    });
    const data = response.json();

    return data;
  } catch (e) {
    console.log(e);
  }
}
