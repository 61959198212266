import { ENDPOINT } from '@/lib/utils';

export async function getMenuFx(locale: string = 'oz', type: string = 'site-map') {
  try {
    const response = await fetch(`${ENDPOINT}/main-page/get-menu?q=${type}`, {
      headers: { language: locale },
    });
    const data = response.json();

    return data;
  } catch (e) {
    console.log(e);
  }
}
